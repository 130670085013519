<template>
    <div class="help">
        <div class="help__section">
            <div class="help__title">
                <router-link :to="{ name: 'help' }" class="help__title-back">
                    <svg-vue icon="back-arrow" />
                </router-link>
                {{ $t("help.create.title") }}
            </div>
            <div class="help__box">
                <div class="help__box-wrapper boxStyle">
                    <div class="helpCreate">
                        <div class="helpCreate__section">
                            <div class="help__subtitle">{{ $t("help.create.topic.title") }}</div>
                            <div class="helpCreate__input">
                                <UiSelect
                                    :placeholder="$t('help.create.topic.placeholder')"
                                    :value="topic"
                                    :searchable="false"
                                    :options="topicOptions"
                                    :error="errors.topic"
                                    @onSelect="(v) => (topic = v)"
                                />
                            </div>
                            <div class="helpCreate__input" v-if="topic && topic.id === 999">
                                <UiInput
                                    :placeholder="$t('help.create.topic.custom')"
                                    :value="topicCustom"
                                    :error="errors.topicCustom"
                                    @onChange="(v) => (topicCustom = v)"
                                />
                            </div>
                        </div>
                        <div class="helpCreate__section">
                            <div class="help__subtitle">{{ $t("help.create.date.title") }}</div>
                            <div class="help__main-text">
                                {{ $t("help.create.date.description") }}
                            </div>
                            <div class="helpCreate__input date">
                                <UiDatePicker
                                    :value="date"
                                    :placeholder="$t('help.create.date.placeholder')"
                                    @onChange="(v) => (date = v)"
                                />
                            </div>
                        </div>
                        <div class="helpCreate__section">
                            <div class="help__subtitle">{{ $t("help.create.description.title") }}</div>
                            <div class="help__main-text">
                                {{ $t("help.create.description.description") }}
                            </div>
                            <div class="helpCreate__input">
                                <UiInput
                                    textarea
                                    rows="3"
                                    :placeholder="$t('help.create.description.placeholder')"
                                    :value="description"
                                    :error="errors.description"
                                    @onChange="(v) => (description = v)"
                                />
                            </div>
                            <div class="helpCreate__input">
                                <UiUploader
                                    :file="uploads"
                                    :allowed-mime="['png', 'jpg', 'jpeg', 'webp', 'pdf', 'mp4']"
                                    :max-size="5"
                                    :include-reader="true"
                                    multiple
                                    @onChange="(files) => (uploads = files)"
                                    @handleError="(err) => (uploadErr = err)"
                                >
                                </UiUploader>
                            </div>
                        </div>
                        <!-- <div class="helpCreate__section">
                            <div class="help__subtitle">{{ $t("help.create.notification.title") }}</div>
                            <div class="help__main-text">
                                {{ $t("help.create.notification.description") }}
                            </div>
                            <div class="helpCreate__input">
                                <UiInput
                                    type="email"
                                    :placeholder="$t('help.create.notification.placeholder')"
                                    :value="email"
                                    :error="errors.email"
                                    @onChange="(v) => (email = v)"
                                />
                            </div>
                        </div> -->
                        <div class="help__cta">
                            <UiButton block :text="$t('help.create.cta')" @click="handleSubmit" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
    // components: { HelpTickets, HelpFaq },
    data() {
        return {
            errors: {},
            topic: null,
            topicCustom: "",
            date: "",
            description: "",
            email: "",
            uploads: [],
            uploadErr: null,
        }
    },
    props: {
        user: Object,
    },
    computed: {
        topicOptions() {
            return [
                ...this.getTopics,
                ...[
                    {
                        id: 999,
                        label: "Другое",
                    },
                ],
            ]
        },
        ...mapGetters("ticket", ["getTopics"]),
    },
    methods: {
        validate() {
            const { topic, topicCustom, date, description, email, uploads } = this
            this.errors = {}

            if (!topic) {
                this.errors.topic = this.$t("help.create.validator.topic")
            }
            if (topic && topic.id === 999 && !topicCustom.trim()) {
                this.errors.topicCustom = this.$t("help.create.validator.topicCustom")
            }
            if (description.trim().length < 10) {
                this.errors.description = this.$t("help.create.validator.description")
            }

            // if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            //     this.errors.email = this.$t("help.create.validator.email")
            // }

            return Object.keys(this.errors).length === 0
        },
        async handleSubmit() {
            const { topic, topicCustom, date, description, email, uploads } = this
            if (!this.validate()) return

            let postObj = {
                message: description,
                // email,
            }

            if (topic.id === 999) {
                postObj["custom_topic"] = topicCustom
            } else {
                postObj["topic_id"] = topic.id
            }

            if (uploads.length) {
                postObj["files"] = uploads
            }
            if (date) {
                postObj["incident_date"] = this.$moment(date).format("YYYY-MM-DD")
            }

            const ticket = await this.createTicket(postObj).catch((err) => {
                this.$toast.error(this.$t("help.create.error"))
            })

            if (ticket) {
                this.$toast.success(this.$t("help.create.success", { id: ticket.id }))
                this.$router.push({ name: "help" })
            }
        },
        ...mapActions("ticket", ["createTicket"]),
    },
}
</script>

<style lang="scss" scoped>
.boxStyle {
    &::before {
        top: 0;
        right: 0;
    }
    &::after {
        bottom: 0;
        top: auto;
        left: 0;
    }
}
.helpCreate {
    position: relative;
    z-index: 2;
    &__section {
        margin-top: 30px;
        &:first-child {
            margin-top: 0;
        }
    }
    &__input {
        margin-top: 15px;
        max-width: 510px;
        &.date {
            max-width: 180px;
        }
    }
}
</style>
