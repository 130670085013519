<template>
    <div v-if="!loading">
        <HelpCreate />
    </div>

    <div v-else>
        <UiLoader theme="block" :loading="true" />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"

import HelpCreate from "@/components/Help/Create.vue"

export default {
    components: {
        HelpCreate,
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            loading: true,
            error: false,
        }
    },
    computed: {
        ...mapGetters("crypto", ["getAccount", "getToken", "isConnected"]),
    },
    async mounted() {
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    methods: {
        async pageReady() {
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                this.$router.push({ name: "signup" })
                return
            }

            await this.fetchAccount()
        },
        async fetchAccount() {
            this.loading = true
            this.error = false

            if (this.getToken) {
                await this.getTopics({ lang: this.$i18n.locale })
            }

            this.loading = false
        },
        ...mapActions("crypto", ["checkRegistration"]),
        ...mapActions("ticket", ["getTopics"]),
    },
}
</script>
<style lang="scss" scoped>
.main {
    width: 100%;
    @media screen and (max-width: 960px) {
        padding: 0 20px;
    }
}
</style>
